import React from 'react';
import { Button, Text, Image, ImageFocalPointPresets, ImageResizeOptions } from 'wix-ui-tpa';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import settingsParams, { HeaderImageCropPosition } from '../settingsParams';
import stylesParams, { Alignment, PageLayout } from '../stylesParams';
import { style, classes, vars } from './header.st.css';
import { IMAGE_ROUTE } from '../../../constants/image-route';
import { getShadowOffsets } from '../../../utils/get-shadow-angles';
import { DataHook } from '../../../types/data-hook';

type HeaderProps = {
  isLoggedIn: boolean;
  onNavigateToMyRewardsPage(): void;
  hasMyRewardsPage: boolean;
  loyaltyProgram: LoyaltyProgram;
};

const alignmentToStateMap = {
  [Alignment.Left]: 'left',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'right',
};

const imagePositionMap = {
  [HeaderImageCropPosition.TopLeft]: ImageFocalPointPresets.topLeft,
  [HeaderImageCropPosition.Top]: ImageFocalPointPresets.topCenter,
  [HeaderImageCropPosition.TopRight]: ImageFocalPointPresets.topRight,
  [HeaderImageCropPosition.Left]: ImageFocalPointPresets.centerLeft,
  [HeaderImageCropPosition.Middle]: ImageFocalPointPresets.center,
  [HeaderImageCropPosition.Right]: ImageFocalPointPresets.centerRight,
  [HeaderImageCropPosition.BottomLeft]: ImageFocalPointPresets.bottomLeft,
  [HeaderImageCropPosition.Bottom]: ImageFocalPointPresets.bottomCenter,
  [HeaderImageCropPosition.BottomRight]: ImageFocalPointPresets.bottomRight,
};

const layoutToStateMap = {
  [PageLayout.Grid]: 'grid',
  [PageLayout.List]: 'list',
  [PageLayout.Side]: 'side',
};

export const Header = ({ isLoggedIn, onNavigateToMyRewardsPage, hasMyRewardsPage, loyaltyProgram }: HeaderProps) => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const programName = settings.get(settingsParams.programName);
  const programTitle = settings.get(settingsParams.programTitle);
  const backgroundImage = settings.get(settingsParams.headerImageNew);
  const backgroundImagePosition = settings.get(settingsParams.headerImageCropPosition) as HeaderImageCropPosition;
  const buttonText = settings.get(settingsParams.buttonLoggedIn);
  const buttonTextAnonymous = settings.get(settingsParams.buttonAnonymous);
  const alignment = isMobile ? Alignment.Center : (styles.get(stylesParams.headerAlignment) as Alignment);
  const showProgramName = styles.get(stylesParams.showProgramName);
  const showProgramTitle = styles.get(stylesParams.showProgramTitle) && !!programTitle.trim();
  const pageLayout = styles.get(stylesParams.pageLayout) as PageLayout;
  const splitHeader = isMobile ? false : styles.get(stylesParams.headerSplit) && pageLayout === PageLayout.List;
  const shadowOffsets = getShadowOffsets({
    angle: styles.get(stylesParams.headerShadowAngle),
    distance: styles.get(stylesParams.headerShadowDistance),
  });
  const showLoyaltyProgramName =
    settings.get(settingsParams.programNameChanged) === false &&
    programName === settings.getDefaultValue(settingsParams.programName) &&
    loyaltyProgram.name;

  return (
    <div
      data-hook={DataHook.Header}
      className={style(classes.root, {
        showShadow: styles.get(stylesParams.headerShowShadow),
        splitHeader,
        pageLayout: isMobile ? 'mobile' : layoutToStateMap[pageLayout],
      })}
      style={{
        [vars.shadowXOffset]: shadowOffsets.xOffset + 'px',
        [vars.shadowYOffset]: shadowOffsets.yOffset + 'px',
      }}
    >
      {styles.get(stylesParams.showCoverImage) && backgroundImage && (
        <Image
          data-hook={DataHook.CoverImage}
          className={classes.backgroundImage}
          src={backgroundImage}
          focalPoint={imagePositionMap[backgroundImagePosition]}
          resize={ImageResizeOptions.cover}
          fluid
        />
      )}
      <div
        className={style(classes.content, {
          alignment: alignmentToStateMap[alignment],
        })}
      >
        {splitHeader ? (
          <img
            alt={t('app.image.background-image')}
            className={classes.icon}
            src={IMAGE_ROUTE(loyaltyProgram.pointDefinition?.icon?.url ?? '')}
          />
        ) : null}
        {showProgramName ? (
          <Text tagName="h1" className={classes.title} data-hook={DataHook.ProgramName}>
            {showLoyaltyProgramName ? loyaltyProgram.name : programName}
          </Text>
        ) : null}
        {showProgramTitle ? (
          <Text tagName="p" className={classes.subtitle} data-hook={DataHook.ProgramTitle}>
            {programTitle}
          </Text>
        ) : null}

        {(hasMyRewardsPage || !isLoggedIn) && (
          <Button
            upgrade
            data-hook={DataHook.Button}
            className={classes.button}
            onClick={() => onNavigateToMyRewardsPage()}
          >
            {isLoggedIn ? buttonText : buttonTextAnonymous}
          </Button>
        )}
      </div>
    </div>
  );
};
